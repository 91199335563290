

import { createApp } from 'vue';
import { gsap } from "gsap";
import mitt from 'mitt';

const emitter = mitt();

// Our main CSS



import '../css/app.css'
// layzyload and/or play/pause inline videos when they are in/out of view
const autoplaying_videos = document.querySelectorAll('video[playsinline]')

if (autoplaying_videos && autoplaying_videos.length) {
  import('./modules/inline-videos').then((module) => {
    module.default(autoplaying_videos)
  })
}

const scroll_effects = document.querySelectorAll('.scroll-effects')

if (scroll_effects && scroll_effects.length) {
  import('./modules/lenis').then((module) => {
    module.default(scroll_effects, emitter)
  })
  import('./modules/gsap').then((module) => {
    module.default(scroll_effects)
  })
}


const splide = document.querySelectorAll('.splide')

if (splide && splide.length) {

  import('./modules/newsSlider').then((module) => {
    module.default(splide)
  }
)}


/**
 * 🦄: Now do your magic.
 */

const cases = document.getElementById('js-cases');

if (cases) {
  const casesApp = createApp({
    data() {
      return {
        caseToggles: [],
        caseContents: {},
      };
    },
    methods: {
      toggleCase(caseId, forceExpand = false, scrollToCase = false) {
        const content = this.caseContents[caseId];
        if (!content) return;

        const isVisible = content.classList.contains('visible');

        if (isVisible && !forceExpand) {
          // Hide the content
          content.classList.remove('visible');
          content.style.maxHeight = '0px';
        } else if (!isVisible || forceExpand) {
          // Show the content
          content.classList.add('visible');

          // Set max-height to content's scrollHeight to expand it
          content.style.maxHeight = content.scrollHeight + 'px';

          if (scrollToCase) {
            // Scroll to the case after content has expanded
            setTimeout(() => {
              const toggleButton = document.querySelector(`.js-toggle-case[data-case-id="${caseId}"]`);
              if (toggleButton) {
                toggleButton.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }
            }, 500); // Delay matches the transition duration
          }
        }
      },
    },
    mounted() {
      // Initialize case toggles and contents
      this.caseToggles = document.querySelectorAll('.js-toggle-case');

      this.caseToggles.forEach((toggle) => {
        const caseId = toggle.getAttribute('data-case-id');
        const content = document.querySelector(`.js-case-content[data-case-id="${caseId}"]`);
        if (content) {
          // Store the content element
          this.caseContents[caseId] = content;

          // Initially hide the content
          content.style.maxHeight = '0px';
          content.style.overflow = 'hidden';
          content.classList.remove('visible');

          // Attach click event listener
          toggle.addEventListener('click', () => {
            this.toggleCase(caseId);
          });
        }
      });

      // Handle URL hash
      this.$nextTick(() => {
        const hash = window.location.hash;
        if (hash) {
          const caseId = hash.substring(1); // Remove the '#' character
          if (this.caseContents[caseId]) {
            // Expand the case and scroll to it
            this.toggleCase(caseId, true, true);
          }
        }
      });
    },
    beforeUnmount() {
      // Clean up event listeners
      this.caseToggles.forEach((toggle) => {
        toggle.removeEventListener('click', this.toggleCase);
      });
    },
  });

  // Mount the app
  casesApp.mount(cases);
}






const menu = createApp({
  data() {
    return {
      lastScrollTop: 0,
      mainMenu: null,
      menuToggles: [],
      overlayMenu: null,
      animateFadeElements: [],
      specialBorders: [],
    };
  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop < 100) {
        // At the top
        this.mainMenu.classList.remove('hide');
        this.mainMenu.classList.remove('bg');
      } else if (scrollTop > this.lastScrollTop) {
        // Scrolling down and not at the top
        this.mainMenu.classList.add('hide');
        this.mainMenu.classList.remove('show');
        this.mainMenu.classList.add('bg');
      } else {
        // Scrolling up or at the top
        this.mainMenu.classList.remove('hide');
        this.mainMenu.classList.add('show');
      }
      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    },
    toggleOverlayMenu() {
      this.overlayMenu.classList.toggle('visible');

      const isVisible = this.overlayMenu.classList.contains('visible');

      if (isVisible) {
        emitter.emit('lenis:stop');
        document.body.style.overflow = 'hidden';
        // Animate the overlay fade-in
        gsap.to(this.overlayMenu, {
          opacity: 1,
          duration: 0.2,
          ease: 'power2.out',
          onStart: () => {
            this.overlayMenu.style.pointerEvents = 'auto';
          },
        });

        // After overlay fade-in, animate the text and borders
        gsap
          .timeline({ delay: 0.1 })
          .fromTo(
            this.animateFadeElements,
            { opacity: 0 },
            {
              opacity: 1,
              duration: 0.5,
              ease: 'power2.out',
              stagger: 0.1,
            }
          )
          .fromTo(
            this.specialBorders,
            { scaleX: 0, transformOrigin: 'left' },
            {
              scaleX: 1,
              duration: 0.5,
              ease: 'power2.out',
              stagger: 0.1,
            },
            '-=0.8' // Start slightly earlier
          );
      } else {
        // Animate the overlay fade-out
        emitter.emit('lenis:start');
        document.body.style.overflow = '';
        gsap.to(this.overlayMenu, {
          opacity: 0,
          duration: 0.5,
          ease: 'power2.in',
          onComplete: () => {
            this.overlayMenu.style.pointerEvents = 'none';
          },
        });

        // Hide the text and border elements
        gsap.set(this.animateFadeElements, { opacity: 0, y: 20 });
        gsap.set(this.specialBorders, { scaleX: 0, transformOrigin: 'left' });
      }
    },
  },
  mounted() {
    // Initialize elements
    this.mainMenu = document.querySelector('.main-menu');
    this.menuToggles = document.querySelectorAll('.menu-toggle');
    this.overlayMenu = document.querySelector('.overlay-menu');
    this.animateFadeElements = this.overlayMenu.querySelectorAll('.animate-fade-menu');
    this.specialBorders = this.overlayMenu.querySelectorAll('.special-border-menu');

    // Attach scroll event listener
    window.addEventListener('scroll', this.handleScroll);

    // Attach click event listener to menu-toggle buttons
    this.menuToggles.forEach((toggle) => {
      toggle.addEventListener('click', this.toggleOverlayMenu);
    });
  },
  beforeUnmount() {
    // Clean up event listeners
    window.removeEventListener('scroll', this.handleScroll);
    this.menuToggles.forEach((toggle) => {
      toggle.removeEventListener('click', this.toggleOverlayMenu);
    });
  },
});

menu.mount('#main-menu');
